:root {
  --theme-1: rgb(219, 82, 77);
  --theme-2: rgb(70, 142, 145);
  --theme-3: rgb(67, 126, 168);
}

.app_l {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  color: white;
  transition: background-color 0.5s ease-in-out 0s;
  padding-bottom: 12px;
  box-sizing: border-box;
}

.theme-1 {
  background-color: var(--theme-1);
}
.theme-2 {
  background-color: var(--theme-2);
}
.theme-3 {
  background-color: var(--theme-3);
}

.container {
  padding: 0px 12px;
  max-width: 620px;
  margin: auto;
}

.header {
  background-color: inherit;
  width: 100%;
  height: 60px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}

.header img {
  height: 40px;
}
.header h1 {
  display: flex;
  align-items: center;
  color: white;
  margin: 0px;
  padding: 10px 0px;
  font-size: 20px;
}

.menu-main {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.menu-main button {
  border-radius: 4px;
  opacity: 0.9;
  background: none rgba(255, 255, 255, 0.2);
  box-shadow: none;
  margin-left: 10px;
  font-size: 13px;
  padding: 8px 12px;
  min-width: 70px;
  border: none;
  color: white !important;
}

button {
  cursor: pointer;
}

button:active {
  transform: translateY(2px);
  box-shadow: none;
}

.text-center {
  text-align: center;
}

.timer-bar {
  background-color: rgba(0, 0, 0, 0.1);
  height: 1px;
  margin-bottom: 40px;
}

.timer-app {
  max-width: 480px;
  margin: auto;
}

.timer-app .inner-view {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px 0px 30px;
  border-radius: 6px;
  margin-bottom: 20px;
  width: 100%;
}

.timer-type button {
  border: none;
  color: white;
  margin: 0px;
  border-radius: 4px;
  font-size: 16px;
  padding: 2px 12px;
  height: 28px;
  background: none;
  box-shadow: none;
  font-weight: 300;
}

.timer-type button.active {
  background: none rgba(0, 0, 0, 0.15);
  font-weight: bold;
}

.timer {
  font-size: 120px;
  font-weight: bold;
  margin-top: 20px;
}

.action {
  position: relative;
  display: inline-block;
  width: 100%;
}

.action .pp {
  border: none;
  margin: 20px 0px 0px;
  padding: 0px 12px;
  border-radius: 4px;
  box-shadow: rgb(235, 235, 235) 0px 6px 0px;
  font-size: 22px;
  height: 55px;
  font-weight: bold;
  width: 200px;
  background-color: white;
  transition: color 0.5s ease-in-out 0s;
}

.theme-1 .action .pp {
  color: var(--theme-1);
}
.theme-2 .action .pp {
  color: var(--theme-2);
}
.theme-3 .action .pp {
  color: var(--theme-3);
}

.action .pp:active,
.action .pp.active {
  transform: translateY(6px);
  box-shadow: none;
}

.action .skipBtn {
  width: calc((100% - 200px) / 2 + 5%);
  position: absolute;
  right: 0px;
  bottom: -6px;
  height: 54px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.action .skipBtn .skip {
  cursor: pointer;
  user-select: none;
  background: none;
  border: none;
  width: 28px;
  height: 28px;
  align-items: center;
  justify-content: center;
  bottom: 9px;
  right: -50px;
  pointer-events: none;
  opacity: 0;
  transition: all 0.2s ease-out 0s;
}
.action .skipBtn .skip img {
  width: 22px;
  opacity: 0.9;
}
.action .skipBtn .skip.active {
  opacity: 1;
}
